import React from 'react';
import ReactMarkdown from 'react-markdown';
import { graphql, Link } from 'gatsby';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { useMediaQuery } from 'usehooks-ts';
import Clouds from '../assets/images/clouds.svg';
import FolderIcon from '../assets/icons/folder.svg';
import { Layout, SEO } from '../components';

const Module = ({
  data: {
    datoCmsModule: {
      description,
      image,
      subCategory,
      seo,
      slug,
      title,
      seoKeywords,
      description2,
      schemaMarkup,
    },
  },
}) => {
  const screen479 = useMediaQuery('(max-width: 479px)');

  return (
    <MathJaxContext>
      <Layout>
        <SEO
          description={seo.description}
          image={seo.image}
          title={seo.title}
          seoKeywords={seoKeywords}
          schema={schemaMarkup && JSON.parse(schemaMarkup)}
        />
        <div className="header">
          <div className="container">
            {screen479 && image?.url && <img src={image.url} alt={image.alt} />}
            <div className="grid main-content">
              <div className="content-block" style={{ minHeight: screen479 ? 'unset' : '630px' }}>
                {!screen479 && image?.url && (
                  <img src={image.url} alt={image.alt} style={{ float: 'right', width: '60%' }} />
                )}
                <h1 className="content-h2">{title}</h1>
                <MathJax hideUntilTypeset="first">
                  {description && <ReactMarkdown className="paragraph" children={description} />}
                  {!description && description2 && (
                    <div dangerouslySetInnerHTML={{ __html: description2 }} />
                  )}
                </MathJax>
                <div className="w-layout-grid grid">
                  {subCategory &&
                    subCategory.map((category) => (
                      <div className="feature-wrap" key={category.id}>
                        <img src={FolderIcon} alt="Folder" className="feature-icon" />
                        <div className="feature-content-wrap">
                          <Link to={`/modules/${slug}/${category.slug}`} className="link">
                            {category.name}
                          </Link>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              {/* {image?.url && <img src={image.url} alt={image.alt} />} */}
            </div>
          </div>
          <img src={Clouds} alt="Clouds" className="pattern-left" />
        </div>
      </Layout>
    </MathJaxContext>
  );
};

export default Module;

const query = graphql`
  query ModuleQuery($slug: String!) {
    datoCmsModule(slug: { eq: $slug }) {
      slug
      comingSoon
      description
      description2
      id
      image {
        alt
        url
      }
      subCategory {
        id
        name
        slug
      }
      position
      title
      seo {
        description
        title
        image {
          url
        }
      }
      seoKeywords {
        id
        keyword
      }
      schemaMarkup
    }
  }
`;
export { query };
